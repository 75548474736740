<template>
  <div class="py-1">
    <b-card no-body>
      <b-card-header>
        <div>
          <h4 class="font-weight-bolder">Create Student</h4>
          <p>You can add new student here</p>
        </div>
      </b-card-header>
      <b-card-body>
        <hr />
        <b-form>
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder text-colorBlack"
                >First Name*</span
              >
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <b-form-input
                  id="firstName"
                  placeholder="First Name"
                  v-bind="firstName"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder text-colorBlack">Last Name</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <b-form-input
                  id="lastName"
                  placeholder="Last Name"
                  v-bind="lastName"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder text-colorBlack">Email</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <b-form-input id="email" placeholder="Email" v-bind="email" />
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder text-colorBlack">Phone no.*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <b-form-input
                  id="phoneNo"
                  placeholder="Phone no."
                  v-bind="phoneNo"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder text-colorBlack"
                >Departments*</span
              >
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <v-select
                  :options="['Math', 'English']"
                  placeholder="Departments"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder text-colorBlack"
                >Class / Program</span
              >
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <v-select
                  :options="['Math', 'English']"
                  placeholder="Class / Program"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder text-colorBlack">Subject*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <v-select
                  :options="['Math', 'English']"
                  placeholder="Subject"
                  :multiple="true"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
      </b-card-body>
      <b-card-footer footer-class="d-flex justify-content-end">
        <div>
          <b-button
            type="submit"
            variant="colorLightBlue"
            rounded
            class="mr-1"
            @click="
              () => {
                this.$router.push({ name: 'InstructorStudent' });
              }
            "
          >
            <span class="text-colorBlue">Cancel</span>
          </b-button>
          <b-button
            type="submit"
            variant="colorBlue"
            rounded
            @click="
              () => {
                this.$router.push({ name: 'InstructorStudent' });
              }
            "
          >
            <span class="text-colorWhite">Add</span>
          </b-button>
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      option: [],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";
</style>
